import {
  Box,
  Button,
  Modal,
  Stack,
  Typography,
  FormControl,
  TextField,
} from "@mui/material";
import * as React from "react";
import { makeStyles } from "@mui/styles";
import { set } from "lodash";

const useStyles = makeStyles({
  style: {
    background: "#FFFFFF",
    boxShadow:
      "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "444px",
    minHeight: "174px",
    padding: "20px",
  },
  inputContainer: {
    "& .MuiFormControl-root": {
      width: "100%",
      marginBottom: "16px !important",
    },
    "& .MuiInputLabel-root": {
      fontSize: 14,
    },
    "& .MuiFormLabel-root-MuiInputLabel-root": {
      fontSize: 14,
    },
  },
  submitBtn: {
    fontSize: 15,
    color: "#fff !important",
    fontWeight: 500,
  },
});

const EditInvoiceDetailsModal = ({ ...props }) => {
  const { title, open, label, inputValue, type, submit, handlePopupClose } =
    props;
  const classes = useStyles();
  const [changeValue, setChangeValue] = React.useState("");
  const [address, setAddress] = React.useState({});

  React.useEffect(() => {
    if (label === "Invoice To" || label === "Ship To") {
      setAddress(inputValue);
    }
    setChangeValue(inputValue);
  }, [inputValue, label]);

  const handleInputChange = (event, name) => {
    const { value } = event.target;
    if (label === "Invoice To" || label === "Ship To") {
      if (name === "Address 1") {
        address.address_line_1 = value;
      }
      if (name === "Address 2") {
        address.address_line_2 = value;
      }
      if (name === "Postal Code") {
        address.postal_code = value;
      }
      if (name === "contact_name") {
        address.contact_name = value;
      }
      if (name === "suburb") {
        address.suburb = value;
      }
      if (name === "state") {
        address.state = value;
      }
      if (name === "country") {
        address.country = value;
      }

      setChangeValue(address);
    } else {
      setChangeValue(value);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handlePopupClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.style}>
        <Stack spacing={3}>
          <Stack direction={"row"}>
            <Typography
              id="modal-modal-title"
              fontWeight={500}
              fontSize={"20px"}
            >
              {title}
            </Typography>
          </Stack>
          <Box className={classes.inputContainer}>
            {label === "Invoice To" || label === "Ship To" ? (
              <>
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    id="standard-basic"
                    label="Address 1"
                    variant="standard"
                    onChange={(e) => handleInputChange(e, "Address 1")}
                    type="text"
                    defaultValue={inputValue?.address_line_1}
                  />
                </FormControl>
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    id="standard-basic"
                    label="Address 2"
                    variant="standard"
                    onChange={(e) => handleInputChange(e, "Address 2")}
                    type="text"
                    defaultValue={inputValue?.address_line_2}
                  />
                </FormControl>
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    id="standard-basic"
                    label="Postal Code"
                    variant="standard"
                    onChange={(e) => handleInputChange(e, "Postal Code")}
                    type="number"
                    defaultValue={inputValue?.postal_code}
                  />
                </FormControl>
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    id="standard-basic"
                    label="Contact Name"
                    variant="standard"
                    onChange={(e) => handleInputChange(e, "contact_name")}
                    type="text"
                    defaultValue={inputValue?.contact_name}
                  />
                </FormControl>

                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    id="standard-basic"
                    label="Suburb"
                    variant="standard"
                    onChange={(e) => handleInputChange(e, "suburb")}
                    type="text"
                    defaultValue={inputValue?.suburb}
                  />
                </FormControl>

                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    id="standard-basic"
                    label="State"
                    variant="standard"
                    onChange={(e) => handleInputChange(e, "state")}
                    type="text"
                    defaultValue={inputValue?.state}
                  />
                </FormControl>

                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    id="standard-basic"
                    label="Country"
                    variant="standard"
                    onChange={(e) => handleInputChange(e, "country")}
                    type="text"
                    defaultValue={inputValue?.country || "Australia"}
                  />
                </FormControl>
              </>
            ) : (
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  id="standard-basic"
                  label={label}
                  variant="standard"
                  onChange={(e) => handleInputChange(e, label)}
                  type={type}
                  defaultValue={inputValue}
                />
              </FormControl>
            )}
          </Box>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ marginTop: "10px !important" }}
          >
            <Button onClick={handlePopupClose}>CLOSE</Button>
            <Button
              className={classes.submitBtn}
              variant="contained"
              onClick={() => submit(label, changeValue)}
            >
              Submit
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default EditInvoiceDetailsModal;
