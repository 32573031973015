import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { Box, InputBase, Tooltip } from "@mui/material";
import { InfoRounded } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import ActionsButton from "../SalesOrders/ActionsButton";
import moment from "moment";
import { NavLink as RouterLink } from "react-router-dom";

const StyleRouterLink = styled(RouterLink)({
  textDecoration: "none",
  color: "blue",
});

const useStyles = makeStyles({
  salesOrdersFilterBar: {
    margin: "14px 0px 16px",
    backgroundColor: "#EFEFEF",
    padding: "16px",
  },
  filterButton: {
    fontSize: 15,
    color: "#fff !important",
    height: "42px",
    "&:hover": {
      backgroundColor: "#fff !important",
      color: "#512879 !important",
      border: "1px solid #512879",
    },
  },
  dataGrid: {
    "& .MuiTablePagination-displayedRows": {
      fontSize: 12,
    },
    "& .MuiTablePagination-select": {
      fontSize: 12,
    },
    "& .MuiTablePagination-selectLabel": {
      fontSize: 12,
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none !important",
    },
  },
  salesOrderFilterTitle: {
    color: "rgba(0, 0, 0, 0.87) !important",
    fontSize: "14 !important",
  },
  salesOrderFilterContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});

const openInvoicePaymentView = (params) => {
  return (
    <StyleRouterLink to={`/admin/invoices/${params?.id}/view`}>
      {params?.id}
    </StyleRouterLink>
  );
};

const columns = [
  {
    field: "id",
    headerName: "Invoice ID",
    renderCell: (params) => openInvoicePaymentView(params),
    resizable: false,
  },
  {
    field: "created_at",
    headerName: "Date",
    width: 100,
    renderCell: (params) => moment(params.row.created_at).format("DD/MM/YYYY"),
  },
  {
    field: "shipped_date",
    headerName: "Dispatch Date",
    width: 100,
    renderCell: (params) =>
      params.row.shipped_date
        ? moment(params.row.shipped_date).format("DD/MM/YYYY")
        : "",
  },
  {
    field: "organization_name",
    headerName: "Organization Name",
    sortable: true,
    width: 150,
    minWidth: 100,
    valueGetter: (params) => `${params.row.organization?.name || ""}`,
  },
  {
    field: "comment",
    headerName: "Comment",
    sortable: true,
    width: 300,
    minWidth: 100,
    valueGetter: (params) => `${params.row.comment || ""}`,
    renderCell: (params) => {
      // if (params.value.length > 20)
      //   return (
      //     <Tooltip title={params.value}>
      //       <InfoRounded />
      //     </Tooltip>
      //   );
      return params.value;
    },
  },
  {
    field: "organization_payment_term",
    headerName: "Payment Term",
    sortable: true,
    width: 150,
    minWidth: 100,
    valueGetter: (params) =>
      `${params.row.organization?.payment_term?.name || ""}`,
  },
  // { field: 'status', headerName: 'Status', renderCell:(params)=>(<StatusChange {...params} component='productionOrders'/>), width:200},
  {
    field: "actions",
    headerName: "Actions",
    width: 150,
    type: "actions",
    renderCell: (params) => (
      <ActionsButton {...params} component="salesOrderInProductionOrders" />
    ),
    resizable: false,
  },
];

const StyledInputBase = styled(InputBase)(() => ({
  borderRadius: "4px",
  backgroundColor: "rgba(0, 0, 0, 0.06)",
  "& .MuiInputBase-input": {
    paddingLeft: `calc(1em)`,
    width: "100%",
    height: "38px",
  },
}));

const AllSalesOrderInProduction = ({ ...props }) => {
  const { productionOrders, status, paginationData, handlePageChange } = props;

  const classes = useStyles();

  const [rows, setRows] = React.useState([]);
  const [filteredResults, setFilteredResults] = React.useState([]);

  const [searchCustomer, setSearchCustomer] = React.useState("");

  React.useEffect(() => {
    if (status === 0) {
      setRows(productionOrders);
      setFilteredResults(productionOrders);
    }
    if (searchCustomer === "") {
      setFilteredResults(productionOrders);
    }
  }, [status, productionOrders, searchCustomer]);

  const handleFilter = () => {
    const filteredRows = rows.filter((f) =>
      f?.organization_name?.toLowerCase().includes(searchCustomer.toLowerCase())
    );

    if (searchCustomer !== "") {
      setFilteredResults(filteredRows);
    } else {
      setFilteredResults(rows);
    }
  };

  return (
    <>
      {/* <Box className={classes.salesOrdersFilterBar}>
            <Typography component={'span'} className={classes.salesOrderFilterTitle}>
                Filter production orders
            </Typography>
            <Box className={classes.salesOrderFilterContainer}>
                <Box sx={{mr:2}}>
                    <StyledInputBase
                    placeholder="Customer Name"
                    inputProps={{ 'aria-label': 'search' , type:'text'}}
                    onChange={(e)=>{
                        setSearchCustomer(e.target.value);

                    }}
                    />
                </Box>
                <Button variant="contained" className={classes.filterButton} onClick={handleFilter}>
                    filter
                </Button>
            </Box>
        </Box> */}
      <Box style={{ height: 650, width: "100%" }}>
        <DataGrid
          className={classes.dataGrid}
          rows={filteredResults}
          pageSize={paginationData?.per_page}
          pagination
          getRowId={(row) => row.id}
          checkboxSelection
          rowCount={paginationData?.total}
          onPageChange={handlePageChange}
          columns={columns}
          paginationMode="server"
        />
      </Box>
    </>
  );
};

export default AllSalesOrderInProduction;
