import Paper from "@mui/material/Paper";
import { React } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { alertActions } from "./actions";
import { history } from "./helpers";
import NotificationProvider from "./Providers/NotificationProvider";
import LoadingProvider from "./Providers/LoadingProvider";

// auth -----------------------------------------------------------------------------------------------------------------
import Auth from "./containers/Auth/Auth";
import Login from "./containers/Auth/Login/Login";

// customer -------------------------------------------------------------------------------------------------------------
import ProtectedCustomerRoute from "./components/common/auth/ProtectedCustomerRoute/ProtectedCustomerRoute.compoent";
import CustomerDashboard from "./containers/Customer/CustomerDashboard";
import CustomerStore from "./containers/Customer/CustomerStore";
import CustomerStoreCart from "./containers/Customer/CustomerStoreCart/CustomerStoreCart";
import CustomerStoreCheckoutSuccess from "./containers/Customer/CustomerStoreCheckoutSuccess";
import CustomerStoreItem from "./containers/Customer/CustomerStoreItem";

// admin
import ProtectedAdminRoute from "./components/common/auth/ProtectedAdminRoute/ProtectedAdminRoute.component";
import AdminDashboard from "./containers/Admin/AdminDashboard";

// parameter management
import ParameterAdd from "./containers/ParameterManagement/ParameterAdd";
import ParameterEdit from "./containers/ParameterManagement/ParameterEdit";
import ParameterList from "./containers/ParameterManagement/ParameterList";
import ParamaeterManagementHome from "./containers/ParameterManagement/ParameterManagementHome";

// User management
import UserAdd from "./containers/UserManagemet/UserAdd";
import UserEdit from "./containers/UserManagemet/UserEdit";
import UserList from "./containers/UserManagemet/UserList/index.";

import ProfileEdit from "./containers/ProfileManagement/ProfileEdit";

// Role management
import RoleAdd from "./containers/RoleManagement/RoleAdd";
import RoleEdit from "./containers/RoleManagement/RoleEdit";
import RoleList from "./containers/RoleManagement/RoleList/index.";

// Organiztion management
import OrganizationAdd from "./containers/OrganizationManagement/OrganizationAdd";
import OrganizationEdit from "./containers/OrganizationManagement/OrganizationEdit";
import OrganizationList from "./containers/OrganizationManagement/OrganizationList";

// quotation
import AcceptQuotation from "./Pages/AcceptQuotation";
import QuotationPage from "./Pages/QuotationPage";

// procebook
import PriceBookContainer from "./components/pages/PriceBookComponents/PriceBookContainer";

// production orders
import ProductionOrder from "./components/ProductionOrder/ProductionOrderContainer";
import ViewProductionOrder from "./containers/ProductionOrders/ViewProductionOrder";

// invoices
import InvoiceContainer from "./components/Invoice/InvoiceContainer";

// sales orders
import SalesOrderCustomer from "./Pages/SalesOrderCustomer";
import SalesOrderPage from "./Pages/SalesOrderPage";
import SalesOrdersContainer from "./components/SalesOrders/SalesOrdersContainer";
import AdminAcceptSalesOrder from "./components/pages/SalesOrders/AdminAcceptSalesOrder";

// Admin Web Stores
import WebStoreList from "./containers/WebStoreManagement/WebStoreList/index.";
import WebStoreSingle from "./containers/WebStoreManagement/WebStoreSingle";
import WebstoreSingleEdit from "./containers/WebStoreManagement/WebstoreSingleEdit";
import WebStoeAddItemSingleEdit from "./containers/WebStoreManagement/WebStoeAddItemSingleEdit";

// Shopify
import ShopifyList from "./containers/ShopifyManagement/ShopifyList/index.";
import ShopifySingle from "./containers/ShopifyManagement/ShopifySingle";
import ShopifySingleView from "./containers/ShopifyManagement/ShopifySingleView";
import ShopifyAddItemSingleEdit from "./containers/ShopifyManagement/ShopifyAddItemSingleEdit";

// 404 ------------------------------------------------------------------------------------------------------------------
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import InvoicesComponent from "./components/Invoice/Invoices";
import ViewInvoicePaymentDetails from "./components/Invoice/ViewInvoicePaymentDetails";
import SalesOrderProceedComponent from "./components/SalesOrderCustomer/SalesOrderProceedComponent";
import NotFound from "./containers/misc/NotFound/NotFound";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AllProductionOrderItemView from "./components/ProductionOrder/AllProductionOrderItemView";
import AllProductionOrders from "./components/ProductionOrder/AllProductionOrders";
import ProductionOrderView from "./components/ProductionOrder/ProductionOrderView";
import CreateNewSalesOrderContainer from "./components/SalesOrder/CreateNewSalesOrder/CreateNewSalesOrderContainer";
import PaymentManagementContainer from "./components/admin/PaymentManagment/PaymentManagementContainer";
import { CustomerQuotations } from "./components/client-pages/quotations/CustomerQuotations";
import { CustomerViewQuotation } from "./components/client-pages/quotations/CustomerViewQuotation";
import InvoicesCustomerContainer from "./components/client/Invoice/InvoicesCustomerContainer";
import SalesOrderCustomerContainer from "./components/client/SalesOrders/SalesOrderCustomerContainer";
import ViewAdminQuotation from "./components/pages/Quotations/ViewQuotation";
import EditQuotation from "./components/QuotationComponent/EditQuotation";
import CustomerLogin from "./containers/Auth/Login/CustomerLogin";
import QuotationsPage from "./components/pages/Quotations/QuotationsPage";
import SalesOrdersPage from "./components/pages/SalesOrders/SalesOrdersPage";
import InvoicesPage from "./components/pages/Invoices/InvoicesPage";
import FactoryInvoices from "./components/pages/FactoryInvoice/FactoryInvoces";
import FactoryInvoiceDetailView from "./components/pages/FactoryInvoice/FactoryInvoiceDetailView/FactoryinvoiceDetailView";
import GenerateFactoryInvoices from "./components/pages/GenerateFactoryInvoices/GenerateFactoryInvoces";
import GenerateResellerInvoices from "./components/pages/ResellerInvoice/GenerateResellerInvoces";
import ResellerInvoices from "./components/pages/ResellerOrderInvoice/ResellerInvoces";
import ResellerInvoiceDetailView from "./components/pages/ResellerOrderInvoice/ResellerInvoiceDetailView/ResellerInvoiceDetailView";
import CustomerFirstTimeLogin from "./containers/Auth/Login/CustomerFirstTimeLogin";
import PreProductionApprovals from "./components/pages/PreProductionApproval/PreProductionApprovals";
import PreProdAprDetailedView from "./components/pages/PreProductionApproval/PreProductionApprovalDetailViews/PreProdApprovalDetailedView";
import PreProductionApprovalClient from "./components/client-pages/PreProAprClient/PreProductionApprovalsClient";
import CustomerForgetPassword from "./containers/Auth/Login/customerForgetPassword";
import ChangePassword from "./containers/Auth/Login/resetPassword";
import ChangePw from "./containers/Auth/Login/changePassword";
import { PRE_PRODUCTION_APPROVAL } from "./constants/Constants";
import PreProdAprDetailedViewClient from "./components/client-pages/PreProAprClient/PreProductionApprovalDetailViewClient/PrePrrodApprovalDetailedViewClient";
import { ProductionOrderTemplateForm } from "./components/admin/parameterManagement/ParameterCard/ParameterForm/ProductionOrderTemplate";
import ProductionOrderTemplateView from "./containers/ParameterManagement/ParameterView/ProductionOrderTemplateView";

import CustomerSalesReport from "./components/Reports/CustomerSalesReport";
import COGSReport from "./components/Reports/COGSReport";
import OrderAverageReport from "./components/Reports/OrderAverageReport";
import OrdersQuantitiesReport from "./components/Reports/OrdersQuantitiesReport";
import OrdersReport from "./components/Reports/OrdersReport";
import SalesReport from "./components/Reports/SalesReport";
import OrderUnitsReport from "./components/Reports/OrderUnitsReport";

const App = (props) => {
  const dispatch = useDispatch();

  history.listen((location, action) => {
    dispatch(alertActions.clear());
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <LoadingProvider>
        <NotificationProvider>
          <Paper className="App" elevation={0}>
            <BrowserRouter>
              <Routes>
                {/* Auth */}
                <Route path="auth" element={<Auth />}>
                  <Route path="login" element={<Login />} />
                </Route>

                {/* customer login*/}
                <Route path="/login" element={<Auth />}>
                  <Route index element={<CustomerLogin />} />
                </Route>

                {/* customer first time login*/}
                <Route path="/first-time-login" element={<Auth />}>
                  <Route index element={<CustomerFirstTimeLogin />} />
                </Route>

                {/* customer forgot password*/}
                <Route path="/forgot-password" element={<Auth />}>
                  <Route index element={<CustomerForgetPassword />} />
                </Route>

                <Route path="/Change-password" element={<Auth />}>
                  <Route index element={<ChangePw />} />
                </Route>

                {/* Customer */}
                <Route path="/" element={<ProtectedCustomerRoute />}>
                  <Route path="/dashboard" element={<CustomerDashboard />} />

                  <Route path="/store">
                    <Route index element={<CustomerStore />} />

                    <Route path=":itemId" element={<CustomerStoreItem />} />
                    <Route path="/store/cart">
                      <Route index element={<CustomerStoreCart />} />
                      <Route
                        path="/store/cart/success"
                        element={<CustomerStoreCheckoutSuccess />}
                      />
                    </Route>
                  </Route>

                  <Route path="/quotations">
                    <Route index element={<CustomerQuotations />} />
                    <Route path=":id" element={<CustomerViewQuotation />} />
                  </Route>

                  <Route
                    path="/sales-orders"
                    element={<SalesOrderCustomerContainer />}
                  />
                  <Route
                    path="/sales-order/proceed/:id"
                    element={<SalesOrderProceedComponent />}
                  />
                  <Route
                    path="/sales-orders/customer/:id"
                    element={<SalesOrderCustomer />}
                  />
                  <Route
                    path="/invoices"
                    element={<InvoicesCustomerContainer />}
                  />

                  {/* <Route path="/pattern-approvals">
                    <Route index element={<PreProductionApprovalClient />} />
                    <Route
                      path=":id"
                      element={<PreProdAprDetailedViewClient />}
                    />
                  </Route> */}

                  <Route path="/pattern-approvals">
                    <Route
                      index
                      element={
                        <PreProductionApprovals
                          type={PRE_PRODUCTION_APPROVAL.NAMES.DESIGN}
                        />
                      }
                    />
                  </Route>

                  <Route
                    path="/pre-production-approval/:id"
                    element={<PreProdAprDetailedView />}
                  />

                  <Route path="/prototype-approvals">
                    <Route
                      index
                      element={
                        <PreProductionApprovals
                          type={PRE_PRODUCTION_APPROVAL.NAMES.PROTOTYPE}
                        />
                      }
                    />
                  </Route>
                </Route>

                {/* Admin */}
                <Route path="/admin" element={<ProtectedAdminRoute />}>
                  <Route path="/admin/dashboard" element={<AdminDashboard />} />

                  <Route path="/admin/parameter-management">
                    <Route index element={<ParamaeterManagementHome />} />

                    <Route path=":parameter">
                      <Route index element={<ParameterList />} />
                      <Route
                        path="/admin/parameter-management/:parameter/add"
                        element={<ParameterAdd />}
                      />
                      <Route
                        path="/admin/parameter-management/:parameter/:id/edit"
                        element={<ParameterEdit />}
                      />
                    </Route>

                    <Route
                      path="/admin/parameter-management/production-order-templates/:id/view"
                      element={<ProductionOrderTemplateView />}
                    />
                  </Route>

                  <Route path="/admin/user-management">
                    <Route path="/admin/user-management/users">
                      <Route index element={<UserList />} />
                      <Route
                        path="/admin/user-management/users/add"
                        element={<UserAdd />}
                      />
                      <Route
                        path="/admin/user-management/users/:id/edit"
                        element={<UserEdit />}
                      />
                    </Route>

                    <Route path="/admin/user-management/roles">
                      <Route index element={<RoleList />} />
                      <Route
                        path="/admin/user-management/roles/add"
                        element={<RoleAdd />}
                      />
                      <Route
                        path="/admin/user-management/roles/:id/edit"
                        element={<RoleEdit />}
                      />
                    </Route>
                  </Route>

                  <Route path="/admin/profile" element={<ProfileEdit />} />

                  <Route path="/admin/organization-management">
                    <Route index element={<OrganizationList />} />
                    <Route
                      path="/admin/organization-management/add"
                      element={<OrganizationAdd />}
                    />
                    <Route
                      path="/admin/organization-management/:id/edit"
                      element={<OrganizationEdit />}
                    />
                  </Route>

                  <Route path="/admin/quotation" element={<QuotationPage />} />

                  {/* <Route path="/admin/quotation/:id" element={<EditQuotation />} /> */}

                  <Route path="/admin/quotations">
                    <Route index element={<QuotationsPage />} />
                    <Route
                      path="/admin/quotations/:id"
                      element={<ViewAdminQuotation />}
                    />
                    <Route
                      path="/admin/quotations/:id/edit"
                      element={<EditQuotation />}
                    />
                  </Route>

                  <Route
                    path="/admin/priceBook"
                    element={<PriceBookContainer />}
                  />

                  <Route path="/admin/production-orders">
                    <Route index element={<AllProductionOrders />} />
                    <Route path=":id/:view" element={<ProductionOrderView />} />
                  </Route>

                  {/* <Route path="/admin/production-orders_item">
                    <Route
                      path=":id/:view"
                      element={<AllProductionOrderItemView />}
                    />
                  </Route> */}
                  <Route path="/admin/production-orders_item">
                    <Route path=":id/:view" element={<ViewProductionOrder />} />
                  </Route>

                  <Route
                    path="/admin/production-orders/:proceededProductionOrder"
                    element={<ProductionOrder />}
                  ></Route>

                  <Route path="/admin/factory-invoice">
                    <Route index element={<FactoryInvoices />} />
                    <Route path=":id" element={<FactoryInvoiceDetailView />} />
                  </Route>

                  <Route
                    path="/admin/Generate-factoryInvoices"
                    element={<GenerateFactoryInvoices />}
                  />

                  <Route
                    path="/admin/Generate-reseller-invoice"
                    element={<GenerateResellerInvoices />}
                  />

                  <Route path="/admin/pattern-approvals">
                    <Route
                      index
                      element={
                        <PreProductionApprovals
                          type={PRE_PRODUCTION_APPROVAL.NAMES.DESIGN}
                        />
                      }
                    />
                  </Route>

                  <Route
                    path="/admin/pre-production-approval/:id"
                    element={<PreProdAprDetailedView />}
                  >
                    <Route
                      path=":initiate"
                      element={<PreProdAprDetailedView />}
                    />
                  </Route>

                  <Route path="/admin/prototype-approvals">
                    <Route
                      index
                      element={
                        <PreProductionApprovals
                          type={PRE_PRODUCTION_APPROVAL.NAMES.PROTOTYPE}
                        />
                      }
                    />
                  </Route>

                  <Route
                    path="/admin/change-password"
                    element={<ChangePassword />}
                  />

                  <Route path="/admin/reseller-invoices">
                    <Route index element={<ResellerInvoices />} />
                    <Route path=":id" element={<ResellerInvoiceDetailView />} />
                  </Route>

                  <Route path="/admin/invoices">
                    <Route index element={<InvoicesPage />} />
                    <Route path=":id" element={<InvoiceContainer />} />
                    <Route
                      path=":id/:view"
                      element={<ViewInvoicePaymentDetails />}
                    />
                  </Route>

                  <Route
                    path="/admin/payments"
                    element={<PaymentManagementContainer />}
                  />

                  <Route
                    path="/admin/invoice/:id/:customer"
                    element={<InvoiceContainer />}
                  ></Route>

                  {/* <Route
                    path="/admin/webstore-management/:name"
                    element={<WebStoreSingle />}
                  /> */}
                  <Route
                    path="/admin/sales-orders"
                    element={<SalesOrdersPage />}
                  />
                  <Route
                    path="/admin/sales-orders/:id/:view"
                    element={<SalesOrderPage />}
                  />
                  <Route
                    path="/admin/sales-orders/:id"
                    element={<SalesOrderPage />}
                  />
                  <Route
                    path="/admin/sales-orders/customer/:id"
                    element={<SalesOrderCustomer />}
                  />

                  <Route
                    path="/admin/sales-order/proceed/:id"
                    element={<SalesOrderProceedComponent />}
                  />

                  <Route
                    path="/admin/sales-orders/accept/:id"
                    element={<AdminAcceptSalesOrder />}
                  />

                  <Route
                    path="/admin/sales-order"
                    element={<CreateNewSalesOrderContainer />}
                  />

                  <Route path="/admin/webstore-management">
                    <Route index element={<WebStoreList />} />
                    <Route
                      path="/admin/webstore-management/:id"
                      element={<WebStoreSingle />}
                    />
                    <Route
                      path="/admin/webstore-management/:id/items/:item"
                      element={<WebstoreSingleEdit />}
                    />
                    <Route
                      path="/admin/webstore-management/:id/storeItem/:item"
                      element={<WebStoeAddItemSingleEdit />}
                    />
                  </Route>
                  
                  <Route path="/admin/shopify-management">
                    <Route index element={<ShopifyList />} />
                    <Route
                      path="/admin/shopify-management/:id"
                      element={<ShopifySingle />}
                    />
                    <Route
                      path="/admin/shopify-management/:id/store/:storeId"
                      element={<ShopifySingleView />}
                    />
                    <Route
                      path="/admin/shopify-management/:id/storeItem/:item"
                      element={<ShopifyAddItemSingleEdit />}
                    />
                  </Route>

                  <Route
                    path="/admin/reports/orders-report"
                    element={<OrdersReport />}
                  />
                  <Route
                    path="/admin/reports/ordered-quantities-report"
                    element={<OrdersQuantitiesReport />}
                  />
                  <Route
                    path="/admin/reports/sales-report"
                    element={<SalesReport />}
                  />
                  <Route
                    path="/admin/reports/customer-sales-report"
                    element={<CustomerSalesReport />}
                  />
                  <Route
                    path="/admin/reports/cogs-report"
                    element={<COGSReport />}
                  />
                  <Route
                    path="/admin/reports/order-units-report"
                    element={<OrderUnitsReport />}
                  />
                  <Route
                    path="/admin/reports/order-average-report"
                    element={<OrderAverageReport />}
                  />
                </Route>

                {/* open routes */}
                <Route
                  path="/quotation/:id/accept"
                  element={<AcceptQuotation />}
                />

                {/* Not found */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>
          </Paper>
        </NotificationProvider>
      </LoadingProvider>
    </LocalizationProvider>
  );
};

export default App;
