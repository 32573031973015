export const rolePermissions = {
  priceBook: {
    name: "Price Book",
    permissions: [
      {
        id: 1,
        name: "View Pricebook Records",
      },
      {
        id: 2,
        name: "Create and Edit Pricebook Records",
      },
    ],
  },
  webStore: {
    name: "Web Store",
    permissions: [
      {
        id: 3,
        name: "Web Store Management",
      },
    ],
  },
  shopifyStore: {
    name: "Shopify Store",
    permissions: [
      {
        id: 21,
        name: "Shopify Store Management",
      },
    ],
  },
  salesOrders: {
    name: "Sales Orders",
    permissions: [
      {
        id: 4,
        name: "Sales Orders",
      },
    ],
  },
  quotations: {
    name: "Quotations",
    permissions: [
      {
        id: 5,
        name: "Quotations",
      },
    ],
  },
  invoicing: {
    name: "Invoicing",
    permissions: [
      {
        id: 6,
        name: "Invoices",
      },
    ],
  },
  payments: {
    name: "Payments",
    permissions: [
      {
        id: 7,
        name: "Payments",
      },
    ],
  },
  productionOrders: {
    name: "Production Orders",
    permissions: [
      {
        id: 8,
        name: "Production Orders",
      },
      {
        id: 9,
        name: "Production Order Level One Approval",
      },
      {
        id: 10,
        name: "Production Order Level Two Approval",
      },
    ],
  },
  shipping: {
    name: "Shipping",
    permissions: [
      {
        id: 11,
        name: "Shipping",
      },
    ],
  },
  userManagement: {
    name: "User Management",
    permissions: [
      {
        id: 12,
        name: "User Management",
      },
    ],
  },
  roleManagement: {
    name: "Role Management",
    permissions: [
      {
        id: 13,
        name: "Role Management",
      },
    ],
  },
  organizationManagement: {
    name: "Organization Management",
    permissions: [
      {
        id: 14,
        name: "Organization Management",
      },
    ],
  },
  parameterManagement: {
    name: "Parameter Management",
    permissions: [
      {
        id: 15,
        name: "Parameter Management",
      },
    ],
  },
  reports: {
    name: "Reports",
    permissions: [
      {
        id: 16,
        name: "Reports",
      },
    ],
  },
  salesAgents: {
    name: "Sales Agents",
    permissions: [
      {
        id: 17,
        name: "Sales Agent Quotations",
      },
      {
        id: 18,
        name: "Sales Agent Sales Orders",
      },
      {
        id: 19,
        name: "Sales Agent Invoices",
      },
      {
        id: 20,
        name: "Sales Agent Payments",
      },
    ],
  },
};

export const PERMISSIONS = {
  VIEW_PRICEBOOK_RECORDS: "View Pricebook Records",
  CREATE_AND_EDIT_PRICEBOOK_RECORDS: "Create and Edit Pricebook Records",
  WEB_STORE_MANAGEMENT: "Web Store Management",
  SHOPIFY_STORE_MANAGEMENT: "Shopify Store Management",
  SALES_ORDERS: "Sales Orders",
  QUOTATIONS: "Quotations",
  INVOICES: "Invoices",
  PAYMENT: "Payments",
  PRODUCTION_ORDERS: "Production Orders",
  PRODUCTION_ORDER_LEVEL_ONE: "Production Order Level One Approval",
  PRODUCTION_ORDER_LEVEL_TWO: "Production Order Level Two Approval",
  SHIPPING: "Shipping",
  USER_MANAGEMENT: "User Management",
  ROLE_MANAGEMENT: "Role Management",
  ORGANIZATION_MANAGEMENT: "Organization Management",
  PARAMETER_MANAGEMENT: "Parameter Management",
  REPORTS: "Reports",
  SALES_AGENT_QUOTATIONS: "Sales Agent Quotations",
  SALES_AGENT_SALES_ORDERS: "Sales Agent Sales Orders",
  SALES_AGENT_INVOICES: "Sales Agent Invoices",
  SALES_AGENT_PAYMENTS: "Sales Agent Payments",
};
