import React, { useState } from "react";
import {
  Divider,
  Button,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
  Grid,
  FormGroup,
  FormControl,
  FormHelperText,
  FormControlLabel,
  InputLabel,
  Checkbox,
  Box,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import NamesForm from "./NamesForm.component";
import styles from "./QuantityInput.module.scss";

// Assumed to be available from your codebase
import { apiConstants } from "../../../../../constants";
import { getUserToken } from "../../../../../helpers";

const QuantityForm = (props) => {
  const {
    sizeOptions,
    size = "",
    quantity = "",
    customize = false,
    customizations = [],
    mode = "create",
    editIndex = null,
    updateSizeQunatity,
  } = props;

  const [toggle, setToggle] = useState(customize);
  const [namesNumbers, setNamesNumbers] = useState(customizations);
  const [uploadFilePath, setUploadFilePath] = useState("");
  const [uploadFile, setUploadFile] = useState("");

  const initialValues = {
    size: mode === "edit" ? size : "",
    quantity: mode === "edit" ? quantity : "",
    customize: mode === "edit" ? customize : false,
  };

  const formikRef = React.createRef();

  const debug = false;

  const validationSchema = Yup.object({
    size: Yup.string().required("Size is required"),
    quantity: Yup.number()
      .required("Quantity is required")
      .min(1, "Minimum quantity is 1")
      .typeError("Quantity must be a number"),
    customize: Yup.boolean().nullable(),
  });

  const downloadUsingAnchorElement = async () => {
    const anchor = document.createElement("a");
    anchor.href = uploadFile;
    document.body.appendChild(anchor);
    anchor.click();
  };

  const handleUploadFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const token = getUserToken();
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: formData,
      };
      fetch(
        `${apiConstants.API_URL}/file/sales-order-item-details`,
        requestOptions
      )
        .then((response) => response.text())
        .then((text) => {
          const res = text && JSON.parse(text);
          if (res?.data?.url) {
            // Save the returned file path as custom_size_path
            setUploadFile(res.data.url);
            setUploadFilePath(res.data.path);
          }
        })
        .catch((error) => console.error("File upload error:", error));
    }
  };

  const handleSubmit = (values, { resetForm }) => {
    // Include custom_size_path along with customizations in the submission object
    const szValues = {
      ...values,
      customizations: namesNumbers,
      custom_size_path: uploadFilePath,
      custom_size_url: uploadFile
    };
    updateSizeQunatity(szValues, mode, editIndex);
    setNamesNumbers([]);
    setUploadFilePath("");
    resetForm();
    setToggle(false);
  };

  const handleToggle = (e, setFieldValue) => {
    setToggle(e.target.checked);
    setFieldValue("customize", e.target.checked);
  };

  return (
    <Box sx={{ p: 2, background: "#f9f9f9", borderRadius: 1 }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        innerRef={formikRef}
        onSubmit={handleSubmit}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <Form noValidate autoComplete="off">
            <Grid container spacing={{ xs: 2, sm: 2 }} sx={{ pt: 0, pb: 0 }}>
              <Grid item xs={12} md={3}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={styles.selectField}
                  size="small"
                  error={touched.size && Boolean(errors.size)}
                >
                  <InputLabel sx={{ backgroundColor: "#fff" }}>
                    Item Size
                  </InputLabel>
                  <Select
                    id="size"
                    name="size"
                    value={values.size}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    renderValue={(value) => `${value.toUpperCase()}`}
                  >
                    {sizeOptions.map((sizeItem) => {
                      return (
                        sizeItem?.status === 1 && (
                          <MenuItem
                            key={sizeItem?.id}
                            value={sizeItem?.code}
                            sx={{ textTransform: "uppercase" }}
                          >
                            {sizeItem?.name}
                          </MenuItem>
                        )
                      );
                    })}
                  </Select>
                  {touched.size && Boolean(errors.size) && (
                    <FormHelperText>{errors.size}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="quantity"
                  name="quantity"
                  label="Item Quantity"
                  value={values.quantity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.quantity && Boolean(errors.quantity)}
                  helperText={touched.quantity && errors.quantity}
                  variant="outlined"
                  className={styles.textField}
                  size="small"
                  type="number"
                  min="0"
                />
              </Grid>

              <Grid item xs={12} md={mode === "edit" ? 6 : 4}>
                <FormGroup>
                  <FormControlLabel
                    sx={{
                      float: mode === "edit" ? "right" : "none",
                    }}
                    control={
                      <Checkbox
                        checked={toggle}
                        onChange={(e) => handleToggle(e, setFieldValue)}
                        id="customize"
                        name="customize"
                      />
                    }
                    label="Customize Names & Numbers"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} md={2}>
                <Button
                  color="secondary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  size="large"
                  sx={{
                    float: "right",
                    width: {
                      xs: "100%",
                      sm: "auto",
                    },
                    mt: "-0.3rem",
                  }}
                  className="btn-form-submit"
                >
                  {mode === "edit" ? "Update" : "Add"} Item
                </Button>
              </Grid>
            </Grid>

            {/* File upload option appears when "Customize" is checked */}
            {values.customize && (
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      borderRadius: "8px",
                      backgroundColor: "rgba(239, 239, 239, 0.65)",
                      border: "1px dashed #9E9E9E",
                      height: "59px",
                      width: "100%",
                      padding: "0 10px",
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ mt: 1 }}
                    >
                      <Box>
                        <Typography>File Name</Typography>
                      </Box>
                      <Box>
                        <label htmlFor="excel-upload">
                          <Button
                            variant="outlined"
                            style={{ width: "60px", pointerEvents: "none" }}
                          >
                            Upload
                          </Button>
                        </label>
                        <input
                          type="file"
                          id="excel-upload"
                          name="excel-upload"
                          accept=".xls,.xlsx"
                          onChange={handleUploadFile}
                          style={{
                            position: "absolute",
                            pointerEvents: "none",
                            visibility: "hidden",
                          }}
                        />
                      </Box>
                    </Stack>
                  </Box>
                  {uploadFile && (
                    <>
                      <iframe
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                          uploadFile
                        )}`}
                        title="Excel Preview"
                        width="100%"
                        height="50%"
                        style={{ border: "none" }}
                      />
                      <Button onClick={downloadUsingAnchorElement}>
                        Download Sizes and Names Excel File
                      </Button>
                    </>
                  )}
                </Grid>

                {/* Render the NamesForm component if quantity is more than 0 */}
                {/* {values.quantity > 0 && (
                  <Grid item xs={12}>
                    <NamesForm
                      quantity={values.quantity}
                      namesNumbers={namesNumbers}
                      setNamesNumbers={setNamesNumbers}
                      mode={mode}
                    />
                  </Grid>
                )} */}
              </Grid>
            )}

            {debug && (
              <>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default QuantityForm;
