import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  Typography,
  Link,
  Box,
  Button,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import { organizationActions } from "../../../actions";
import Intro from "../../../components/atoms/Intro/Intro.component";
import { apiConstants } from "../../../constants";
import { authHeader, getUserToken } from "../../../helpers";

const OrganizationSingle = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const organization = useSelector(
    (state) => state.organizations?.parameter?.data
  );

  // State for Shopify stores with modal form
  const [shopifyStores, setShopifyStores] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentStore, setCurrentStore] = useState(null);
  const [formState, setFormState] = useState({
    name: "",
    type: "",
    url: "",
    access_token: "",
    api_key: "",
    shopify_store_name: "",
    image: "",
  });

  useEffect(() => {
    dispatch(organizationActions.getSingleParameter(id));
  }, [dispatch, id]);

  // Fetch Shopify stores for this organization on mount and when id changes
  useEffect(() => {
    fetchShopifyStores();
  }, [id]);

  const fetchShopifyStores = () => {
    fetch(`${apiConstants.API_URL}/shopify_stores/${id}`, {
      method: "GET",
      headers: authHeader(),
    })
      .then((response) => response.json())
      .then((data) => {
        setShopifyStores(data?.data || []);
      });
  };

  const handleOpenAddModal = () => {
    setCurrentStore(null);
    setFormState({
      name: "",
      type: "",
      url: "",
      access_token: "",
      api_key: "",
      shopify_store_name: "",
      image: "",
    });
    setOpenModal(true);
  };

  const handleEditStore = (store) => {
    setCurrentStore(store);
    setFormState({
      name: store.name,
      type: store.type,
      url: store.url,
      access_token: store.access_token,
      api_key: store.api_key,
      shopify_store_name: store.shopify_store_name,
      image: store.image || "",
      image_url: store.image_url || "",
    });
    setOpenModal(true);
  };

  const handleDeleteStore = (store) => {
    if (window.confirm("Are you sure you want to delete this store?")) {
      fetch(
        `${apiConstants.API_URL}/shopify_stores/delete/${store.id}`,
        {
          method: "DELETE",
          headers: authHeader(),
        }
      )
        .then((response) => response.json())
        .then(() => {
          fetchShopifyStores();
        });
    }
  };

  const handleViewStore = (store) => {
    // Navigate to the store view page. Adjust the route as necessary.
    navigate(`/admin/shopify-management/${id}/store/${store.id}`);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentStore(null);
    setFormState({
      name: "",
      type: "",
      url: "",
      access_token: "",
      api_key: "",
      shopify_store_name: "",
      image: "",
    });
  };

  const handleSubmitForm = () => {
    if (currentStore) {
      // Edit existing store
      fetch(
        `${apiConstants.API_URL}/shopify_stores/update/${currentStore.id}`,
        {
          method: "PUT",
          headers: { ...authHeader(), "Content-Type": "application/json" },
          body: JSON.stringify(formState),
        }
      )
        .then((response) => response.json())
        .then(() => {
          fetchShopifyStores();
          handleCloseModal();
        });
    } else {
      // Create new store
      fetch(
        `${apiConstants.API_URL}/shopify_stores/create/${id}`,
        {
          method: "POST",
          headers: { ...authHeader(), "Content-Type": "application/json" },
          body: JSON.stringify(formState),
        }
      )
        .then((response) => response.json())
        .then(() => {
          fetchShopifyStores();
          handleCloseModal();
        });
    }
  };

  // Image upload handler
  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const formData = new FormData();
    formData.append("image", file);
    const token = getUserToken();
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    };

    fetch(`${apiConstants.API_URL}/images/shopify`, requestOptions)
      .then((response) => response.text())
      .then((text) => {
        const data = text && JSON.parse(text);
        // Update formState with the uploaded image path
        setFormState((prevState) => ({
          ...prevState,
          image: data?.data?.path,
          image_url: data?.data?.url,
        }));
      });
  };

  // Breadcrumbs for navigation
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/admin/shopify-management/"
    >
      Shopify Management
    </Link>,
    <Typography key="2">Store - {organization?.name}</Typography>,
  ];

  return (
    <>
      <Intro
        pageTitle={`Store - ${organization?.name || ""}`}
        pageTitleShort="Store"
        breadcrumbs={breadcrumbs}
        additionalWidgets=""
      />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
      >
        <Box sx={{ p: 2 }}>
          <Button variant="contained" onClick={handleOpenAddModal}>
            Add New Store
          </Button>
        </Box>
      </Stack>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          {shopifyStores.map((store) => (
            <Grid item xs={12} sm={6} md={4} key={store.id}>
              <Card sx={{ maxWidth: 345, boxShadow: 3, borderRadius: 2 }}>
                <CardMedia
                  component="img"
                  height="180"
                  image={
                    store.image_url ||
                    "https://via.placeholder.com/300x180?text=No+Image"
                  }
                  alt={store.name}
                />
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {store.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Type: {store.type}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    URL: {store.url}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Access Token: {store.access_token}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    API Key: {store.api_key}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Shopify Store Name: {store.shopify_store_name}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button onClick={() => handleEditStore(store)}>Edit</Button>
                  <Button onClick={() => handleDeleteStore(store)}>
                    Delete
                  </Button>
                  <Button onClick={() => handleViewStore(store)}>View</Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Dialog open={openModal} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <DialogTitle>
          {currentStore ? "Edit Store" : "Add New Store"}
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Name"
            fullWidth
            value={formState.name}
            onChange={(e) =>
              setFormState({ ...formState, name: e.target.value })
            }
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Type</InputLabel>
            <Select
              value={formState.type}
              onChange={(e) =>
                setFormState({ ...formState, type: e.target.value })
              }
              label="Type"
            >
              <MenuItem value="retails">Retails</MenuItem>
              <MenuItem value="wholesale">Wholesale</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="URL"
            fullWidth
            value={formState.url}
            onChange={(e) =>
              setFormState({ ...formState, url: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Access Token"
            fullWidth
            value={formState.access_token}
            onChange={(e) =>
              setFormState({ ...formState, access_token: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="API Key"
            fullWidth
            value={formState.api_key}
            onChange={(e) =>
              setFormState({ ...formState, api_key: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Shopify Store Name"
            fullWidth
            value={formState.shopify_store_name}
            onChange={(e) =>
              setFormState({
                ...formState,
                shopify_store_name: e.target.value,
              })
            }
          />
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" component="label">
              Upload Image
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={onFileChange}
              />
            </Button>
            {formState.image_url && (
              <Box sx={{ mt: 2 }}>
                <img
                  src={formState.image_url}
                  alt="Store"
                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                />
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={handleSubmitForm}>
            {currentStore ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrganizationSingle;
