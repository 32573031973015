import { apiConstants } from "../constants";
import { authHeader } from "../helpers";

export const webstoreService = {
  getSingleParameter,
  getAllParameters,
  // addParameter,
  // updateParameter,
  // deleteParameter,
  // clear,
};

// // get single parameter
function getSingleParameter(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  // return fetch(`${apiConstants.API_URL}/production-orders?organization_name=${name}`, requestOptions).then(
  //   handleResponse
  // );
  return fetch(
    `${apiConstants.API_URL}/store-items?organization_id=${id}`,
    requestOptions
  ).then(handleResponse);
}

// get all parameter
function getAllParameters() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${apiConstants.API_URL}/organizations?store_enabled=true&page=1`,
    requestOptions
  ).then(handleResponse);
}


// handle response
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout()
        // window.location.reload(true);
      }

      const error = (data && data.error) || response.statusText;
      let errorMessage = "";
      if (data?.code === 500) {
        errorMessage = (data && data.message) || response.statusText;
      } else {
        errorMessage = Object.values(error).map((errorKey) => {
          return errorKey[0];
        });
      }
      return Promise.reject(errorMessage);
    }

    return data;
  });
}
