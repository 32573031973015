import { webstoreConstants } from "../constants";
import { webstoreService } from "../services";

export const webstoreActions = {
  getSingleParameter,
  getAllParameters,
  clear,
};

// get single parameter
function getSingleParameter(id) {
  return (dispatch) => {
    dispatch(request(id));

    webstoreService.getSingleParameter(id).then(
      (parameter) => {
        dispatch(success(parameter));
        // dispatch(alertActions.success(parameter));
      },
      (error) => {
        dispatch(failure(error));
        // dispatch(alertActions.error(error));
      }
    );
  };

  function request(parameter) {
    return { type: webstoreConstants.GET_SINGLE_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: webstoreConstants.GET_SINGLE_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: webstoreConstants.GET_SINGLE_FAILURE, error };
  }
}

// get all parameter
function getAllParameters() {
  return (dispatch) => {
    dispatch(request());

    webstoreService.getAllParameters().then(
      (items) => dispatch(success(items)),
      (error) => dispatch(failure(error))
    );
  };

  function request(params) {
    return { type: webstoreConstants.GET_ALL_REQUEST, params };
  }
  function success(items) {
    return { type: webstoreConstants.GET_ALL_SUCCESS, items };
  }
  function failure(error) {
    return { type: webstoreConstants.GET_ALL_FAILURE, error };
  }
}

// clear parameter
function clear() {
  return { type: webstoreConstants.CLEAR };
}
