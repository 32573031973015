import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Autocomplete,
  TextField,
  FormControl,
  Grid,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useStyles } from "../../../../Theme/Style";
import { useState, useEffect } from "react";
import { Field, FieldArray, Form, Formik } from "formik";
import { Delete } from "@mui/icons-material";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useNotification } from "../../../../Contexts/GlobalNotificationContext";
import {
  useSubmitEmbroideryMutation,
  useUploadEmbroideryImageMutation,
  useGetEmbroideryQuery,
} from "../../../../services/api/production-order";

const validationSchema = Yup.object({
  positionImages: Yup.array().min(1, "At least one image is required"),
  colorWayImages: Yup.array().length(2, "Exactly two images are required"),
  width: Yup.number().required("Width is required"),
  height: Yup.number().required("Height is required"),
  embroideryDiskName: Yup.string().required("Embroidery Disk Name is required"),
  colorWayComments: Yup.string().optional(),
});

const EmproideryComponent = ({ prodId }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState();
  const { addNotification } = useNotification();

  const [uploadEmbroideryImages, { isLoading: isLoadingImageUpload }] =
    useUploadEmbroideryImageMutation();

  const [submitEmbroidery] = useSubmitEmbroideryMutation();
  const { data: embroideryData, isLoading } = useGetEmbroideryQuery({
    id: prodId,
  });

  const [initialValues, setInitialValues] = useState({
    positionImages: [""],
    colorWayImages: ["", ""],
    width: "",
    height: "",
    embroideryDiskName: "",
    colorWayComments: "",
  });

  useEffect(() => {
    setInitialValues({
      positionImages: embroideryData?.position_images || [""],
      colorWayImages: [
        embroideryData?.img1_url || "",
        embroideryData?.img2_url || "",
      ],
      width: embroideryData?.width || "",
      height: embroideryData?.height || "",
      embroideryDiskName: embroideryData?.disk_name || "",
      colorWayComments: embroideryData?.comment || "",
    });
  
    setPreviewImages({
      positionImages: embroideryData?.position_images || [],
      colorWayImages: [
        embroideryData?.img1_url || "",
        embroideryData?.img2_url || "",
      ],
    });
  }, [embroideryData]);
  

  const [previewImages, setPreviewImages] = useState({
    positionImages: [],
    colorWayImages: [],
  });

  const handleImageChange = (event, setFieldValue, fieldName, index) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFieldValue(`${fieldName}[${index}]`, file);
        setPreviewImages((prev) => ({
          ...prev,
          [fieldName]: [
            ...prev[fieldName].slice(0, index),
            reader.result,
            ...prev[fieldName].slice(index + 1),
          ],
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const onSubmitData = async (values) => {
    try {
      setLoading(true);

      const positionImagesFiles = values.positionImages;
      const uploadPositionImagesPromisees = positionImagesFiles.map((file) => {
        const formData = new FormData();
        formData.append("image", file);
        return uploadEmbroideryImages({ data: formData }).unwrap();
      });

      const uploadedPositionImages = await Promise.all(
        uploadPositionImagesPromisees
      );

      const colorWayImagesFiles = values.colorWayImages;
      const uploadColorWayImagesPromisees = colorWayImagesFiles.map((file) => {
        const formData = new FormData();
        formData.append("image", file);
        return uploadEmbroideryImages({ data: formData }).unwrap();
      });

      const uploadedColorWayImages = await Promise.all(
        uploadColorWayImagesPromisees
      );

      const data = {
        colorWayComments: values.colorWayComments,
        embroideryDiskName: values.embroideryDiskName,
        height: values.height,
        width: values.width,
        positionImages: uploadedPositionImages.map(({ path }) => path),
        colorWayImages: uploadedColorWayImages.map(({ path }) => path),
        production_order_id: prodId,
      };

      await submitEmbroidery(data).unwrap();

      addNotification({
        message: "Embroidery Data is Submitted.",
        type: "success",
      });
    } catch (e) {
      console.log(e);

      const errors = e?.data?.error
        ? Object.values(e.data.error).flat().join(",")
        : "Something went wrong!!";

      addNotification({
        message: errors,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.accordionSummary}
      >
        <Grid container className={classes.accordionSummaryTextContainer}>
          <Grid item xs={4}>
            Embroidery
          </Grid>
          <Grid item xs={4}>
            刺绣
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmitData(values);
          }}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              {/* Position Section */}
              <Typography variant="subtitle1" paddingBottom={2}>
                Position Images
              </Typography>
              <FieldArray name="positionImages">
                {({ push, remove }) => (
                  <>
                    <Grid container spacing={2}>
                      {values.positionImages.map((_, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id={`position-image-${index}`}
                            type="file"
                            onChange={(event) =>
                              handleImageChange(
                                event,
                                setFieldValue,
                                "positionImages",
                                index
                              )
                            }
                          />
                          <label htmlFor={`position-image-${index}`}>
                            <Button variant="outlined" component="span">
                              Upload Image {index + 1}
                            </Button>
                          </label>
                          {previewImages.positionImages[index] && (
                            <Box mt={2}>
                              <img
                                src={previewImages.positionImages[index]}
                                alt={`Position Preview ${index + 1}`}
                                style={{ width: "100%", maxHeight: "200px" }}
                              />
                              <IconButton onClick={() => remove(index)}>
                                <Delete />
                              </IconButton>
                            </Box>
                          )}
                        </Grid>
                      ))}
                      <Grid item xs={12}>
                        <Button variant="contained" onClick={() => push("")}>
                          Add More Images
                        </Button>
                      </Grid>
                    </Grid>
                    {errors.positionImages && touched.positionImages && (
                      <div style={{ color: "red" }}>
                        {errors.positionImages}
                      </div>
                    )}
                  </>
                )}
              </FieldArray>

              <Divider
                orientation="horizontal"
                sx={{
                  marginY: 2,
                }}
              />

              {/* Color Way Section */}
              <Typography variant="subtitle1">Color Way</Typography>
              <Field
                fullWidth
                sx={{ marginTop: 2 }}
                name="colorWayComments"
                as={TextField}
                label="Comment"
                variant="outlined"
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
              <Grid container spacing={2} mt={2}>
                {values.colorWayImages.map((_, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id={`color-way-image-${index}`}
                      type="file"
                      onChange={(event) =>
                        handleImageChange(
                          event,
                          setFieldValue,
                          "colorWayImages",
                          index
                        )
                      }
                    />
                    <label htmlFor={`color-way-image-${index}`}>
                      <Button variant="outlined" component="span">
                        Upload Color Way {index + 1}
                      </Button>
                    </label>
                    {previewImages.colorWayImages[index] && (
                      <Box mt={2}>
                        <img
                          src={previewImages.colorWayImages[index]}
                          alt={`Color Way Preview ${index + 1}`}
                          style={{ width: "100%", maxHeight: "200px" }}
                        />
                      </Box>
                    )}
                  </Grid>
                ))}
              </Grid>
              {errors.colorWayImages && touched.colorWayImages && (
                <div style={{ color: "red" }}>{errors.colorWayImages}</div>
              )}

              <Divider
                orientation="horizontal"
                sx={{
                  marginY: 2,
                }}
              />
              {/* Measurement Section */}
              <Typography variant="subtitle1">Measurement</Typography>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Width"
                    name="width"
                    fullWidth
                    onChange={(e) => setFieldValue("width", e.target.value)}
                    value={values.width}
                    error={touched.width && Boolean(errors.width)}
                    helperText={touched.width && errors.width}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Height"
                    name="height"
                    fullWidth
                    onChange={(e) => setFieldValue("height", e.target.value)}
                    value={values.height}
                    error={touched.height && Boolean(errors.height)}
                    helperText={touched.height && errors.height}
                  />
                </Grid>
              </Grid>

              <Divider
                orientation="horizontal"
                sx={{
                  marginY: 2,
                }}
              />
              {/* Embroidery Disk Name */}
              <Typography variant="subtitle1">Embroidery Disk Name</Typography>

              <Grid item xs={12} mt={2}>
                <TextField
                  label="Embroidery Disk Name"
                  name="embroideryDiskName"
                  fullWidth
                  onChange={(e) =>
                    setFieldValue("embroideryDiskName", e.target.value)
                  }
                  value={values.embroideryDiskName}
                  error={
                    touched.embroideryDiskName &&
                    Boolean(errors.embroideryDiskName)
                  }
                  helperText={
                    touched.embroideryDiskName && errors.embroideryDiskName
                  }
                />
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12} mt={4}>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save
                </LoadingButton>
              </Grid>
            </Form>
          )}
        </Formik>
      </AccordionDetails>
    </Accordion>
  );
};

export default EmproideryComponent;
