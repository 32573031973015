import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  TableContainer,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import Intro from "../../../components/atoms/Intro/Intro.component";
import { authHeader } from "../../../helpers";
import { apiConstants } from "../../../constants";

const ShopifySingleView = () => {
  const { id, storeId } = useParams(); // id: organization id, storeId: store id
  const [storeDetails, setStoreDetails] = useState(null);
  const [storeItems, setStoreItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);

  // For Sync Wizard Multi-Step Form
  const [syncWizardOpen, setSyncWizardOpen] = useState(false);
  const [syncData, setSyncData] = useState([]);
  const [activeStep, setActiveStep] = useState(0);

  // Fetch store details
  useEffect(() => {
    fetch(`${apiConstants.API_URL}/shopify_stores/${id}`, {
      method: "GET",
      headers: authHeader(),
    })
      .then((response) => response.json())
      .then((data) => {
        const stores = data?.data || [];
        const store = stores.find((s) => s.id === parseInt(storeId));
        setStoreDetails(store);
      })
      .catch((error) =>
        console.error("Error fetching store details:", error)
      );
  }, [id, storeId]);

  // Fetch store items using the provided API endpoint
  useEffect(() => {
    fetch(`${apiConstants.API_URL}/shopify_stores/store_items/${storeId}`, {
      method: "GET",
      headers: authHeader(),
    })
      .then((response) => response.json())
      .then((data) => {
        const items = data?.data || [];
        setStoreItems(items);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching store items:", error);
        setIsLoading(false);
      });
  }, [storeId]);

  // Handle selection of items in the table
  const handleSelect = (itemId) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(itemId)) {
        return prevSelected.filter((id) => id !== itemId);
      } else {
        return [...prevSelected, itemId];
      }
    });
  };

  // Open sync wizard and initialize syncData with details for each selected product
  const handleOpenSyncWizard = () => {
    if (selectedItems.length === 0) {
      alert("Please select at least one product to sync.");
      return;
    }
    const initialSyncData = storeItems
      .filter((item) => selectedItems.includes(item.id))
      .map((item) => ({
        id: item.id,
        shopify_price: "",
        style: item.production_order?.style || "-",
        unit_price: item.unit_price,
        gender: item.gender,
      }));
    setSyncData(initialSyncData);
    setActiveStep(0);
    setSyncWizardOpen(true);
  };

  // Handle changes in the shopify_price for the current product
  const handleSyncDataChange = (value) => {
    setSyncData((prevData) =>
      prevData.map((prod, index) =>
        index === activeStep ? { ...prod, shopify_price: value } : prod
      )
    );
  };

  // Navigation in the wizard
  const handleNext = () => {
    if (activeStep < syncData.length - 1) {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    }
  };

  // Submit sync data to the API endpoint
  const handleSubmitSync = () => {
    fetch(
      `${apiConstants.API_URL}/shopify_stores/store_items/sync/${storeId}`,
      {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({ products: syncData }),
      }
    )
      .then((response) => response.json())
      .then(() => {
        setSyncWizardOpen(false);
        setSelectedItems([]); // Clear selections after sync
      })
      .catch((error) => console.error("Error syncing products:", error));
  };

  // Breadcrumbs for navigation
  const breadcrumbs = [
    <Link key="1" to="/admin/shopify-management/">
      Shopify Management
    </Link>,
    <Typography key="2">
      Store - {storeDetails ? storeDetails.name : ""}
    </Typography>,
  ];

  return (
    <>
      <Intro
        pageTitle={`Store - ${storeDetails ? storeDetails.name : ""}`}
        pageTitleShort="Store"
        breadcrumbs={breadcrumbs}
        additionalWidgets=""
      />
      <Container>
        {isLoading ? (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {storeDetails && (
              <Box mb={4}>
                <Typography variant="h5">{storeDetails.name}</Typography>
                <Typography variant="subtitle1">
                  Type: {storeDetails.type}
                </Typography>
                <Typography variant="body2">
                  URL: {storeDetails.url}
                </Typography>
              </Box>
            )}
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              mb={2}
            >
              <Button variant="contained" onClick={handleOpenSyncWizard}>
                Sync Products
              </Button>
            </Stack>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox" />
                      <TableCell>ID</TableCell>
                      <TableCell>Style</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Gender</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {storeItems.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedItems.includes(item.id)}
                            onChange={() => handleSelect(item.id)}
                          />
                        </TableCell>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>
                          {item.production_order?.style || "-"}
                        </TableCell>
                        <TableCell>{item.unit_price}</TableCell>
                        <TableCell>{item.gender}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        )}
      </Container>

      {/* Multi-Step Sync Wizard Dialog */}
      <Dialog
        open={syncWizardOpen}
        onClose={() => setSyncWizardOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Sync Selected Products - Step {activeStep + 1} of {syncData.length}
        </DialogTitle>
        <DialogContent>
          {syncData.length > 0 && (
            <Box>
              <Typography variant="subtitle1">
                Product ID: {syncData[activeStep].id}
              </Typography>
              <Typography variant="body2">
                Style: {syncData[activeStep].style}
              </Typography>
              <Typography variant="body2">
                Current Price: {syncData[activeStep].unit_price}
              </Typography>
              <Typography variant="body2">
                Gender: {syncData[activeStep].gender}
              </Typography>
              <Box mt={2}>
                <TextField
                  label="New Shopify Price"
                  type="number"
                  fullWidth
                  value={syncData[activeStep].shopify_price}
                  onChange={(e) => handleSyncDataChange(e.target.value)}
                />
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSyncWizardOpen(false)}>Cancel</Button>
          {activeStep > 0 && (
            <Button onClick={handleBack}>Back</Button>
          )}
          {activeStep < syncData.length - 1 ? (
            <Button variant="contained" onClick={handleNext}>
              Next
            </Button>
          ) : (
            <Button variant="contained" onClick={handleSubmitSync}>
              Finish
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShopifySingleView;
