import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import EditInvoiceDetailsModal from "../../Common/Modals/EditInvoiceDetailsModal";
import SuccessModal from "../../Common/Modals/SuccessModal";
import ProgressCircularWithBackdrop from "../../Common/ProgressCircular/ProgressCircularWithBackdrop";
import AlertToast from "../../components/common/Toast";
import { apiConstants } from "../../constants";
import { authHeader } from "../../helpers";
import CustomerInformationComponent from "./CustomerInformationComponent";
import InvoiceSummaryDetails from "./InvoiceSummaryDetails";
import ProductsDetailsTable from "./ProductsTable";
import ShippingDetailsComponent from "./ShippingDetailsComponent";
import TermsAndConditionComponent from "./TermsAndConditionComponent";
import { generatePDF } from "../../helpers/pdf-gen";
import ReactQuill from "react-quill";
import { useState } from "react";
import styled from "@emotion/styled";
import { countryService } from "../../services";
import { de } from "date-fns/locale";

const useStyles = makeStyles({
  invoiceHeaderTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "40px 0",
    "& .MuiTypography-root": {
      textTransform: "uppercase",
      fontSize: 24,
      fontWeight: 700,
      color: "rgba(0, 0, 0, 0.87)",
    },
  },
  invoiceHeaderButtonGroup: {
    width: "100%",
    margin: "40px 0",
    borderColor: "transparent",
    "& .MuiButtonGroup-grouped:first-child": {
      color: "#fff !important",
      width: "100%",
      fontSize: 15,
      borderColor: "transparent !important",
      "&:hover": {
        backgroundColor: "#512879 !important",
      },
    },
    "& .MuiButtonGroup-grouped:nth-child(2)": {
      color: "#fff !important",
      width: "100%",
      fontSize: 15,
      backgroundColor: "#3F91D9",
      borderColor: "transparent !important",
    },
    "& .MuiButtonGroup-grouped:last-child": {
      width: "100%",
      color: "#fff !important",
      fontSize: 15,
      backgroundColor: "#12830b",
    },
  },
  invoiceSubHeaderContainer: {
    display: "flex",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontWeight: "400 !important",
      fontSize: "13px !important",
      textTransform: "capitalize",
    },
  },
  editButton: {
    padding: "0 0 0 10px !important",
    fontSize: "13px !important",
    "& .MuiButton-startIcon>*:nth-of-type(1)": {
      fontSize: 13,
    },
  },
  sendInvoiceButton: {
    width: "100%",
    fontWeight: "700 !important",
    fontSize: "16px !important",
    color: "#fff !important",
  },
});
const style = {
  background: "#FFFFFF",
  boxShadow:
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};
const StyledButton2 = styled(Button)({
  color: "white",
});

const InvoiceContainer = ({ view = false }) => {
  const classes = useStyles();
  const { id, customer } = useParams();
  const navigate = useNavigate();

  const popupDetails = {
    title: "",
    open: false,
    label: "",
    value: "",
    type: "",
  };
  const [salesOrderById, setSalesOrderById] = React.useState({});
  const [products, setProducts] = React.useState([]);
  const [customProducts, setCustomProducts] = React.useState([]);
  const [paymentFacilities, setPaymentFacilities] = React.useState([]);
  const [paymentFacility, setPaymentFacility] = React.useState(1);
  const [discountStatus, setDiscountStatus] = React.useState(1);
  const [sponsorPercentage, setSponsorPercentage] = React.useState(0);
  const [sponsorAmount, setSponsorAmount] = React.useState(0);
  const [discountAmount, setDiscountAmount] = React.useState(0);
  const [grandTotal, setGrandTotal] = React.useState(0);
  const [customerName, setCustomerName] = React.useState("");
  const [comment, setComment] = React.useState("");
  const [subCharge, setSubCharge] = React.useState(0);
  const [shippingCharge, setShippingCharge] = React.useState(0);
  const [invoiceTo, setInvoiceTo] = React.useState({});
  const [shipTo, setShipTo] = React.useState({});
  const [abnNo, setAbnNo] = React.useState("");
  const [purchaseOrder, setPurchaseOrder] = React.useState("");
  const [issuedDate, setIssuedDate] = React.useState("");
  const [editPopupDetails, setEditPopupDetails] = React.useState(popupDetails);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [disabledSendInvoice, setDisabledSendInvoice] = React.useState(false);
  const [error, setError] = React.useState({
    value: false,
    name: "",
    message: "",
  });
  const [loadingComponent, setLoadingComponent] = React.useState(false);
  const [invoiceId, setInvoiceId] = React.useState("");
  const [organizationEmail, setOrganizationEmail] = React.useState("");
  const [openProgressCircular, setOpenProgressCircular] = React.useState(false);
  const [additionalBody, setAdditionalBody] = useState(
    "<p>Thank you for ordering with S-Trend Sportswear!</p><p><br></p><p>We are pleased to confirm that your order has entered our production system and will be progressed to the next stage shortly.</p><p>Keep an eye out for further email correspondence in the coming days requesting artwork and pre-production sample approval and we ask that you follow the provided instructions within 48 hours of receipt.</p><p>Below you can access the Invoice with the mentioned URL.&nbsp;We will require a 50% deposit to be paid by 16 Dec, 2023 before production commences for delivery.</p><p><br></p><p>Alternatively for direct deposits, please ensure the correct invoice number is quoted as a reference or a remittance is forwarded to accountsreceivable@s-trend.com.au and allow up to 2 business days for your payment to be processed.</p><p><br></p>"
  );
  const [open, setOpen] = React.useState(false);

  const [toastState, setToastState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [toastMessage, setToastMessage] = React.useState("");
  const handleToastClose = () => setToastState({ open: false });

  const buttonGroupRef = React.createRef();
  const downloadSectionRef = React.createRef();

  React.useEffect(() => {
    setOpenProgressCircular(true);
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
    if (customer || view) {
      fetch(`${apiConstants.API_URL}/invoices/${id}`, requestOptions).then(
        (response) => {
          response.text().then((text) => {
            const data = text && JSON.parse(text);
            const details = data?.data;
            setSalesOrderById(details);
            setProducts(details?.sales_order_items);
            setCustomProducts(details?.custom_sales_order_items);
            //setCustomerName(details.organization.contact_person_name);
            setOrganizationEmail(details?.organization?.email);
            setDiscountAmount(details?.discount_amount);
            setSponsorAmount(details?.sponsered_amount);
            setGrandTotal(
              details?.grand_total -
                details?.discount_amount -
                details?.sponsered_amount
            );
            setSubCharge(details?.extra_charge + details?.artwork_charge);
            setShippingCharge(details?.shipping_charge);
            setIssuedDate(details?.issued_date);
            setAbnNo(details?.abn);
            setInvoiceTo({
              full_address: details?.invoice_to,
              address_line_1: details?.organization.address_line_1,
              address_line_2: details?.organization.address_line_2,
              postal_code: details?.organization.postal_code,
              contact_name: details?.organization.contact_person_name,
              suburb: details?.organization.city,
              state: details?.organization.state,
              country: details?.organization.country,
            });
            setShipTo({
              full_address: details?.ship_to,
              address_line_1: details?.organization.delivery_address_line_1,
              address_line_2: details?.organization.delivery_address_line_2,
              postal_code: details?.organization.delivery_postal_code,

              contact_name: details?.organization.delivery_contact_person_name,
              suburb: details?.organization.delivery_city,
              state: details?.organization.delivery_state,
              country: details?.organization.delivery_country_id,
            });
            setPurchaseOrder(details?.purchase_order);
            setOpenProgressCircular(false);
          });
        }
      );
    } else {
      fetch(
        `${apiConstants.API_URL}/sales-orders/${id}/generate-invoice`,
        requestOptions
      ).then((response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          const details = data?.data;
          setSalesOrderById(details);
          setProducts(details?.items);
          setCustomProducts(details?.custom_items);
          setCustomerName(details.organization.contact_person_name);
          setOrganizationEmail(details?.organization?.email);
          setGrandTotal(
            details?.grand_total - discountAmount * 100 - sponsorAmount
          );
          setSubCharge(details?.extra_charges + details?.artwork_charges);
          setShippingCharge(details?.estimated_shipping_charges);
          setIssuedDate(details?.created_at);

          countryService
            .getCountryById(details?.organization.country_id)
            .then(({ data: { name: country = "N/A" } }) => {
              console.log("country", country);
              setInvoiceTo({
                full_address: null,
                address_line_1: details?.organization.address_line_1,
                address_line_2: details?.organization.address_line_2,
                postal_code: details?.organization.postal_code,

                contact_name: details?.organization.contact_person_name,
                suburb: details?.organization.city,
                state: details?.organization.state,
                country,
              });
              setShipTo({
                full_address: null,
                address_line_1: details?.organization.delivery_address_line_1,
                address_line_2: details?.organization.delivery_address_line_2,
                postal_code: details?.organization.delivery_postal_code,

                contact_name:
                  details?.organization.delivery_contact_person_name,
                suburb: details?.organization.delivery_city,
                state: details?.organization.delivery_state,
                country,

                // "country": details?.organization.country_id,
              });
            });

          setOpenProgressCircular(false);
        });
      });
    }
    fetch(`${apiConstants.API_URL}/payment-facilities`, requestOptions).then(
      (response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          setPaymentFacilities(data?.data);
        });
      }
    );
  }, [id, discountAmount, sponsorAmount, customer, view]);

  const amountFormat = (amount) => {
    return parseFloat(amount / 100)?.toFixed(2);
  };

  const amountDecimalFormat = (amount) => {
    if (amount >= 0) {
      return parseFloat(amount).toFixed(2);
    }
  };

  const calculateGrandTotal = (value, name) => {
    let amount = value;
    let total = salesOrderById?.grand_total;
    if (name === "sponsered_percentage") {
      total = total - discountAmount * 100;
      setGrandTotal(total - amount);
    } else {
      total = total - sponsorAmount;
      setGrandTotal(total - amount * 100);
    }
  };

  const handleCustomerInformation = (event, name) => {
    const { value } = event.target;
    let total = salesOrderById?.grand_total;
    if (name === "payment_facility_id") {
      setDiscountAmount(0);
      setSponsorPercentage(0);
      setPaymentFacility(value);
    } else if (name === "discountStatus") {
      setDiscountStatus(value);
    } else if (name === "sponsered_percentage") {
      if (value) {
        setSponsorPercentage(value);
        setSponsorAmount((total / 100) * value);
        calculateGrandTotal((grandTotal / 100) * value, name);
      } else {
        setSponsorPercentage(0);
        setSponsorAmount(0);
        calculateGrandTotal(0, name);
      }
    } else if (name === "discount_amount") {
      if (value) {
        setDiscountAmount(value);
        calculateGrandTotal(value, name);
      } else {
        setDiscountAmount(0);
        calculateGrandTotal(0, name);
      }
    } else if (name === "comment") {
      setComment(value);
    }
  };

  const handleEditInvoiceDetails = (type, name) => {
    if (name === "abn") {
      setEditPopupDetails({
        title: "Edit",
        open: true,
        label: "ABN",
        value: abnNo,
        type: type,
      });
    } else if (name === "purchase_order") {
      setEditPopupDetails({
        title: "Edit",
        open: true,
        label: "Purchase Order",
        value: purchaseOrder,
        type: type,
      });
    } else if (name === "invoice_to") {
      setEditPopupDetails({
        title: "Edit",
        open: true,
        label: "Invoice To",
        value: invoiceTo,
        type: type,
      });
    } else if (name === "ship_to") {
      setEditPopupDetails({
        title: "Edit",
        open: true,
        label: "Ship To",
        value: shipTo,
        type: type,
      });
    }
  };

  const submitEditPopupInvoiceDetails = (label, value) => {
    if (label === "ABN") {
      setAbnNo(value);
    } else if (label === "Purchase Order") {
      setPurchaseOrder(value);
    } else if (label === "Invoice To") {
      if(!value){
        setInvoiceTo(invoiceTo);
      }else{
        setInvoiceTo(value);
      }
    } else if (label === "Ship To") {
      if(!value){
        setShipTo(shipTo);
      }else{
        setShipTo(value);
      }
    }
    handlePopupClose();
  };

  const handlePopupClose = () => {
    setEditPopupDetails({
      open: false,
    });
  };

  const handleSuccessPopupClose = () => {
    setOpenSuccessModal(false);
    setDisabledSendInvoice(true);
    navigate("/admin/invoices");
  };

  const onGenerateInvoicePDF = React.useCallback(() => {
    // const downloadSection = downloadSectionRef.current;
    // const buttonGroup = buttonGroupRef.current;
    // const outerButtonGroup = buttonGroup.parentElement;
    // outerButtonGroup.removeChild(buttonGroup);
    // generatePDF(downloadSection, "invoice.pdf");
    // outerButtonGroup.appendChild(buttonGroup);

    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(`${apiConstants.API_URL}/invoices/download/${id}`, requestOptions)
      .then((response) => {
        response.text().then((text) => {
          const link = text && JSON.parse(text);
          console.log("link", link.data);

          if (link?.data?.url) {
            window.open(link?.data?.url, "_blank");
          } else {
            console.log("Error while downloading invoice");
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [buttonGroupRef, downloadSectionRef]);

  const submitInvoice = () => {
    setOpenProgressCircular(true);
    if (sponsorPercentage > 100) {
      setError({
        value: true,
        name: "sponsered_percentage",
        message: "Sponsorship Percentage maximum value must be 100",
      });
    } else if (
      discountAmount >
      amountFormat(
        salesOrderById.sub_total -
          (subCharge + salesOrderById.estimated_shipping_charges)
      )
    ) {
      setError({
        value: true,
        name: "discount_amount",
        message:
          "Discount Amount must be shorter than Sub Total(excluding tax)",
      });
    } else {
      setError({ value: false });
      const addressToString = (ship) => {
        console.log("ship", ship);

        return `${ship.contact_name}, ${ship.address_line_1}, ${ship.address_line_2}, ${ship.postal_code}, ${ship.suburb}, ${ship.state}, ${ship.country}`;

        // let shipping = [];
        // Object.values(ship).forEach((element) => {
        //   shipping.push(element);
        // });

        // return shipping.join(",");
      };
      const salesOrderIds = products.map((item) => {
        return item?.id;
      });
      const customSalesOrderIds = customProducts.map((item) => {
        return item?.id;
      });
      const organizationId = salesOrderById?.organization?.id;
      console.log("invoiceTo submit", invoiceTo);
      
      const invoiceDetails = {
        sales_order_item_ids: salesOrderIds,
        custom_sales_order_item_ids: customSalesOrderIds,
        organization_id: organizationId,
        purchase_order: purchaseOrder,
        ship_to: addressToString(shipTo),
        invoice_to: addressToString(invoiceTo),
        payment_facility_id: paymentFacility,
        sponsered_percentage: sponsorPercentage,
        discount_amount: discountAmount,
        issued_date: issuedDate,
        status_id: 1,
        additional_email_body: additionalBody,
      };

      if (abnNo) invoiceDetails.abn = abnNo;

      if (comment) invoiceDetails.comment = comment;

      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(invoiceDetails),
      };

      fetch(`${apiConstants.API_URL}/invoices`, requestOptions).then(
        (response) => {
          response.text().then((text) => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
              if (response.status === 401) {
                // logout()
              }
              const error = (data && data.error) || response.statusText;
              const errorMessage = Object.values(error).map((errorKey) => {
                return errorKey[0];
              });
              setOpenProgressCircular(false);
              setToastMessage(errorMessage);
              setToastState({
                open: true,
                vertical: "top",
                horizontal: "right",
              });
            } else {
              setOpenProgressCircular(false);
              setInvoiceId(data?.data?.id);
              setOpenSuccessModal(true);
            }
          });
        }
      );
    }
  };

  function submitModel() {
    setOpen(true);
  }

  const onChange = (e) => {
    console.log(e);
    setAdditionalBody(() => e);
  };

  return (
    <div ref={downloadSectionRef}>
      {loadingComponent ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "400px",
          }}
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Box
          sx={{
            overflow: "scroll",
            maxHeight: "100%",
            p: 3,
            width: "100%",
            padding: "0 15px",
            position: "relative",
          }}
        >
          <div id="invoiceContent">
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} sm={12} md={8}>
                <Box className={classes.invoiceHeaderTitle}>
                  <Typography>invoice</Typography>
                  <Box className={classes.invoiceSubHeaderContainer}>
                    <Typography>
                      Issued on {moment(issuedDate).format("DD MMM YYYY")}
                    </Typography>
                    {/* <Button className={classes.editButton} onClick={()=>handleEditInvoiceDetails('date','issued_date')} variant="text" startIcon={<Edit />} >Edit</Button> */}
                  </Box>
                </Box>
              </Grid>
              <Grid
                id="downloadSection"
                item
                xs={12}
                sm={12}
                md={4}
                ref={buttonGroupRef}
              >
                <ButtonGroup
                  variant="contained"
                  className={classes.invoiceHeaderButtonGroup}
                  aria-label="outlined primary button group"
                >
                  {view && (
                    <>
                    <Button onClick={onGenerateInvoicePDF}>download</Button>
                    <Button onClick={onGenerateInvoicePDF}>Print</Button>
                  </>
                        
                      )}
                  {!customer ? (
                    <>
                      {!view && (
                        <Button
                          disabled={disabledSendInvoice}
                          onClick={submitInvoice}
                        >
                          send invoice
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                    <Button onClick={onGenerateInvoicePDF}>download</Button>
                    <Button onClick={onGenerateInvoicePDF}>Print</Button>
                    <Button>Pay online</Button>
                  </>
                  )}
                </ButtonGroup>
              </Grid>
              <Grid id="downloadSection1" item xs={12} md={8}>
                <ShippingDetailsComponent
                  handleEditInvoiceDetails={handleEditInvoiceDetails}
                  abnNo={abnNo}
                  purchaseOrder={purchaseOrder}
                  invoiceTo={invoiceTo}
                  shipTo={shipTo}
                  customer={customer}
                  view={view}
                />
                <ProductsDetailsTable
                  salesOrderById={salesOrderById}
                  products={products}
                  customProducts={customProducts}
                  subCharge={subCharge}
                  amountFormat={amountFormat}
                  customer={customer}
                  view={view}
                  shippingCharge={shippingCharge}
                />
                <TermsAndConditionComponent />
              </Grid>
              <Grid item xs={12} md={4}>
                {!customer && !view && (
                  <CustomerInformationComponent
                    handleCustomerInformation={handleCustomerInformation}
                    customerEmail={organizationEmail}
                    paymentFacilities={paymentFacilities}
                    paymentFacility={paymentFacility}
                    discountStatus={discountStatus}
                    sponsorPercentage={sponsorPercentage}
                    discountAmount={discountAmount}
                    grandTotal={grandTotal}
                    comment={comment}
                    error={error}
                  />
                )}
                <InvoiceSummaryDetails
                  salesOrderById={salesOrderById}
                  subCharge={subCharge}
                  grandTotal={grandTotal}
                  discountAmount={discountAmount}
                  sponsorAmount={sponsorAmount}
                  amountFormat={amountFormat}
                  amountDecimalFormat={amountDecimalFormat}
                  shippingCharge={shippingCharge}
                />
                {!customer && !view && (
                  <Button
                    variant="contained"
                    color="success"
                    className={classes.sendInvoiceButton}
                    onClick={() => submitModel()}
                    // onClick={submitInvoice}
                    disabled={disabledSendInvoice}
                  >
                    SEND INVOICE
                  </Button>
                )}
              </Grid>
            </Grid>
            <EditInvoiceDetailsModal
              open={editPopupDetails.open}
              title={editPopupDetails.title}
              type={editPopupDetails.type}
              label={editPopupDetails.label}
              inputValue={editPopupDetails.value}
              handlePopupClose={handlePopupClose}
              submit={submitEditPopupInvoiceDetails}
            />
            <SuccessModal
              open={openSuccessModal}
              handleClose={handleSuccessPopupClose}
              title={"Invoice Sent Successfully"}
              body={`Invoice #${invoiceId} is successfully sent to the customer email ${organizationEmail}`}
            />
            <AlertToast
              toastMessage={toastMessage}
              toastState={toastState}
              handleClose={handleToastClose}
            />
            <ProgressCircularWithBackdrop open={openProgressCircular} />
          </div>
        </Box>
      )}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction={"column"}
            spacing={2}
            sx={{ padding: "0 5px 0 5px" }}
          >
            <Typography fontSize={"16px"}>
              Dear (<i>customer_name</i>),
            </Typography>
            <ReactQuill
              style={{ height: "230px" }}
              value={additionalBody}
              onChange={onChange}
            />

            <Typography style={{ paddingTop: "50px" }}>
              The invoice can be accessed by the URL (xxxxxxxxxxxxxxxxx) by
              logging into our system.
              <br />
              Have any questions? Please contact customerservice@s-trend.com.au
              or simply reply to this email and we will be in touch as soon as
              possible.
              <br />
              Kind regards,
              <br />
              S-Trend Accounts Team
            </Typography>
          </Stack>
          <Stack direction={"row"} justifyContent="space-between">
            <Button color="success" onClick={() => setOpen(false)}>
              CLOSE
            </Button>
            <StyledButton2
              color={"success"}
              variant="contained"
              onClick={() => submitInvoice()}
              // disabled={disabledSubmitButton}
            >
              Save and Submit
            </StyledButton2>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default InvoiceContainer;
