import React from "react";
import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WebstoreItem from "./WebstoreItem/WebstoreItem.component";

const GarmentCollection = ({ orderedItems }) => {
  console.log("itms", orderedItems);

  return (
    <Accordion sx={{ mb: 2, boxShadow: "none" }} defaultExpanded={true}>
      <AccordionDetails>
        <Grid container spacing={1}>
          {orderedItems.map((item) => {
            const isMigrated = item?.is_old_po === 1 ? true : false;

            return (
              <Grid item key={item?.id} sx={{ width: "20%" }}>
                <WebstoreItem
                  itemId={item.id}
                  name={isMigrated ? item?.style : item?.price?.item?.name}
                  image={
                    item?.image_url !== null
                      ? item?.image_url
                      : item?.thumbnail_url !== null
                      ? item?.thumbnail_url
                      : item?.items[0]?.image_url
                  }
                  styleId={
                    isMigrated
                      ? item?.pattern_id
                      : item?.pattern_id
                      ? item?.pattern_id
                      : item?.style
                  }
                  active={item?.store_item?.is_active === 1 ? true : false}
                  newItem={item?.is_new_item}
                  store={item?.store_item ? item?.store_item : null}
                  productionOrderId={1}
                />
              </Grid>
            );
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const WebstoreCategoryTab = (props) => {
  const { categoryName, orderedItems } = props;

  return (
    <Box component="section">
      <Grid container>
        <Grid item xs={12}>
          {categoryName && (
            <Typography variant="h5" sx={{ pl: 3, mb: 2 }}>
              All {categoryName.toLowerCase() !== "all" ? categoryName : ""}{" "}
              items
              <Divider sx={{ mt: 1 }} />
            </Typography>
          )}
          <GarmentCollection orderedItems={orderedItems} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default WebstoreCategoryTab;
