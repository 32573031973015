import {
  FormControl,
  Stack,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Button,
  FormHelperText,
  Box,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Notification from "../../Common/Notification";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import AlertToast from "../common/Toast";
import { apiConstants } from "../../constants";
import { getUserToken } from "../../helpers";
import { tr } from "date-fns/locale";

// Styled header row for tables
const StyledHeaderRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.neutral.light,
  height: "50px",
  fontWeight: 500,
  textAlign: "center",
}));

// Helper function to truncate file name (customize as needed)
const truncate = (str) => {
  if (!str) return "";
  const parts = str.split("/");
  return parts[3] || str;
};

const SalesOrderItems = (props) => {
  const {
    sizes,
    item_details,
    displayData,
    setData,
    data,
    activeStep,
    editStatus,
    error,
    setError,
    setDisabledSubmitBtn,
    readOnly,
  } = props;

  const [edit, setEdit] = useState(editStatus);
  const [itemDetails, setItemDetails] = useState([]);
  const [itemDetailsWithQuantity, setItemDetailsWithQuantity] = useState([]);
  const [toastState, setToastState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [toastMessage, setToastMessage] = useState("");
  const handleToastClose = () => setToastState({ open: false });
  // State to store the uploaded file path (Excel file) for customization
  const [uploadFile, setUploadFile] = useState("");
  // This state determines whether to show manual entry fields or the Excel upload section.
  const [checkedCustomizeFields, setCheckedCustomizeFields] = useState(false);
  const [checkedCustomizeFieldCheck, setCheckedCustomizeFieldCheck] =
    useState(false);

  // Update local states when props change
  useEffect(() => {
    console.log("item_details data", data);
    console.log("displayData", displayData);
    console.log("itemDetailsWithQuantity", itemDetailsWithQuantity);

    setItemDetails(item_details);
    setUploadFile(displayData?.custom_size_url);
    setEdit(false);
    // Use displayData.flag_name_no to initialize the checkbox state

    setItemDetailsWithQuantity(displayData?.itemDetailsWithQuantity || []);
    setDisabledSubmitBtn(true);

    if (
      displayData?.itemDetailsWithQuantity &&
      displayData?.itemDetailsWithQuantity.length > 0
    ) {
      if (!checkedCustomizeFieldCheck) {
        setCheckedCustomizeFields(displayData?.custom_size_url ? true : false);
      } else {
        setCheckedCustomizeFields(true);
      }
    } else {
      setCheckedCustomizeFields(false);
      setDisabledSubmitBtn(false);
    }

    if (
      item_details.every((el) => el.size_id && el.name && el.number) &&
      displayData?.flag_name_no === 1
    ) {
      setDisabledSubmitBtn(false);
    }
    let sum = 0;
    if (itemDetailsWithQuantity && itemDetailsWithQuantity.length !== 0) {
      sum = itemDetailsWithQuantity
        .map((o) => o.quantity)
        .reduce((a, c) => parseInt(a) + parseInt(c), 0);
    }
    if (
      itemDetailsWithQuantity &&
      itemDetailsWithQuantity.every((el) => el.size_id && el.quantity) &&
      parseInt(sum) === displayData?.qty
    ) {
      setDisabledSubmitBtn(false);
    }
  }, [
    item_details,
    displayData,
    itemDetailsWithQuantity,
    setDisabledSubmitBtn,
  ]);

  // Handler for the "Customize Name and Number" checkbox change.
  const handleCheckedChange = (e) => {
    setCheckedCustomizeFields(e.target.checked);
    setCheckedCustomizeFieldCheck(e.target.checked);
    // Optionally, you might want to clear manual entries when toggling on.
  };

  // Handlers for manual input changes remain unchanged.
  const onChangeFields = (e, index) => {
    const { id, value } = e.target;
    let newData = data;
    newData.items[activeStep].item_details[index][id] = value;
    setData(newData);
    let details = item_details;
    details[index][id] = value;
    setItemDetails(details);
    if (details.every((el) => el.size_id && el.name && el.number)) {
      setDisabledSubmitBtn(false);
    } else {
      setDisabledSubmitBtn(true);
    }
  };

  const onChangeQuantity = (e, index) => {
    const { id, value } = e.target;
    itemDetailsWithQuantity[index][id] = value;
    setItemDetailsWithQuantity(itemDetailsWithQuantity);
    const sizeId = itemDetailsWithQuantity[index].size_id;
    let sum = itemDetailsWithQuantity
      .map((o) => o.quantity)
      .reduce((a, c) => {
        return parseInt(a) + parseInt(c);
      });
    if (parseInt(sum) === displayData?.qty && sizeId !== 0) {
      setError({ status: false, message: "" });
      setDisabledSubmitBtn(false);
    } else if (sum > displayData?.qty) {
      setError({
        status: true,
        message:
          "Total quantity for sizes cannot be greater than the item quantity",
        index: index,
      });
      setDisabledSubmitBtn(true);
    } else {
      setError({ status: false, message: "" });
      setDisabledSubmitBtn(true);
    }
  };

  const handleChange = (e, index, sizeValue) => {
    let newData = data;
    if (sizeValue) {
      newData.items[activeStep].item_details[index].size_id = sizeValue?.id;
      newData.items[activeStep].item_details[index].size_name = sizeValue?.name;
      newData.items[activeStep].item_details[index].selectedSize = {
        id: sizeValue?.id,
        name: sizeValue?.name,
      };
    }
    setData(newData);
    if (
      newData.items[activeStep].item_details.every(
        (el) => el.size_id && el.name && el.number
      )
    ) {
      setDisabledSubmitBtn(false);
    } else {
      setDisabledSubmitBtn(true);
    }
  };

  const handleChangeWithQuantity = (e, index, sizeValue) => {
    let newData = data;
    if (sizeValue) {
      let updated = [...itemDetailsWithQuantity];
      updated[index].size_id = sizeValue?.id;
      updated[index].size_name = sizeValue?.name;
      updated[index].selectedSize = {
        id: sizeValue?.id,
        name: sizeValue?.name,
      };
      setItemDetailsWithQuantity(updated);
      newData.items[activeStep].itemDetailsWithQuantity[index].size_id =
        sizeValue?.id;
      newData.items[activeStep].itemDetailsWithQuantity[index].size_name =
        sizeValue?.name;
      newData.items[activeStep].itemDetailsWithQuantity[index].selectedSize = {
        id: sizeValue?.id,
        name: sizeValue?.name,
      };
      setData(newData);
    }
    let sum = itemDetailsWithQuantity
      .map((o) => o.quantity)
      .reduce((a, c) => parseInt(a) + parseInt(c), 0);
    if (sizeValue?.id && parseInt(sum) === displayData?.qty) {
      setDisabledSubmitBtn(false);
    }
  };

  const sizesProps = {
    options: sizes,
    getOptionLabel: (option) => option?.name || "",
  };

  // --- Excel File Upload Section for Customized Name and Number ---

  const handleUploadFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const token = getUserToken();
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: formData,
      };
      fetch(
        `${apiConstants.API_URL}/file/sales-order-item-details`,
        requestOptions
      )
        .then((response) => response.text())
        .then((text) => {
          const res = text && JSON.parse(text);
          if (res?.data?.url) {
            setUploadFile(res.data.url);
            let newData = data;
            newData.items[activeStep].custom_size_path = res.data.path;
            setData(newData);
          }
        });
    }
  };

  const removeImage = () => {
    setUploadFile("");
  };

  // New function to add a new row for manual quantity entry (used in non-customized section)
  const addNewRow = () => {
    let sum = itemDetailsWithQuantity
      .map((o) => o.quantity)
      .reduce((a, c) => parseInt(a) + parseInt(c), 0);
    const newRow = {
      size_id: 0,
      quantity: "",
      selectedSize: { id: 0, name: "" },
    };
    if (!itemDetailsWithQuantity.every((el) => el.quantity && el.size_id)) {
      setToastMessage("Fill every field");
      setToastState({
        open: true,
        vertical: "top",
        horizontal: "right",
      });
    } else if (sum < displayData?.qty) {
      setItemDetailsWithQuantity([...itemDetailsWithQuantity, newRow]);
      let newData = data;
      newData.items[activeStep].itemDetailsWithQuantity = [
        ...itemDetailsWithQuantity,
        newRow,
      ];
      setData(newData);
    } else if (sum >= displayData?.qty) {
      setToastMessage("Cannot add new row; total quantity exceeded");
      setToastState({
        open: true,
        vertical: "top",
        horizontal: "right",
      });
    }
  };

  const deleteRows = (index) => {
    console.log("hi log");
    const rows = [...itemDetailsWithQuantity];
    rows.splice(index, 1);
    setItemDetailsWithQuantity(rows);
    let newData = data;
    newData.items[activeStep].itemDetailsWithQuantity = [...rows];
    setData(newData);
    setDisabledSubmitBtn(true);
  };

  const downloadUsingAnchorElement = async () => {
    const anchor = document.createElement("a");
    anchor.href = uploadFile;
    document.body.appendChild(anchor);
    anchor.click();
  };

  return (
    <>
      {itemDetailsWithQuantity && itemDetailsWithQuantity.length > 0 && (
        <>
          <Stack spacing={1}>
            <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
              Sizes & Quantities
            </Typography>
            <Typography sx={{ fontSize: "15px" }}>
              Please select the sizes you wish to add to the Order.
            </Typography>
          </Stack>
          <Notification
            title="Notes"
            body="Numbers and names will be printed exactly as per this table so please ensure spelling and case of letters are correct (e.g. Capitals)."
            extra="If the names and/or numbers are not to be printed on garments and are for packing purposes only, please specify under “Comments”."
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedCustomizeFields}
                onChange={handleCheckedChange}
                name="checked"
                disabled={!!readOnly}
              />
            }
            label="Customize Name and Number"
          />

          <Table>
            <thead>
              <StyledHeaderRow>
                <TableCell>Garment Size</TableCell>
                <TableCell>
                  {/* Using "Quantity" when not customizing */}Quantity
                </TableCell>
                <TableCell>
                  {/* "Action" column for manual entry */}Action
                </TableCell>
              </StyledHeaderRow>
            </thead>
            <tbody>
              {itemDetailsWithQuantity?.map((i, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <FormControl
                      fullWidth
                      variant="standard"
                      sx={{ width: "100%", minWidth: "200px" }}
                    >
                      <Autocomplete
                        {...sizesProps}
                        id="controlled-demo"
                        autoComplete
                        includeInputInList
                        value={i?.selectedSize}
                        onChange={(e, value) => {
                          handleChangeWithQuantity(e, index, value);
                        }}
                        disabled={!!readOnly}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Size"
                            variant="standard"
                            disabled={!!readOnly}
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth variant="standard">
                      {edit ? (
                        <TextField
                          size="small"
                          id="quantity"
                          placeholder="Quantity"
                          onChange={(e) => onChangeQuantity(e, index)}
                          type="number"
                          InputLabelProps={{ shrink: true }}
                          disabled={!!readOnly}
                        />
                      ) : (
                        <TextField
                          size="small"
                          placeholder="Quantity"
                          value={i?.quantity}
                          type="number"
                          onClick={() => setEdit(!edit)}
                          InputLabelProps={{ shrink: true }}
                          disabled={!!readOnly}
                        />
                      )}
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => deleteRows(index)}>
                      <RemoveCircleOutline />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
          {!readOnly && (
            <Stack direction="row" justifyContent="space-between">
              <Button
                variant="outlined"
                sx={{ width: "200px" }}
                onClick={addNewRow}
              >
                <AddCircleOutline
                  sx={{ marginRight: "10px", width: "20px", height: "20px" }}
                />{" "}
                Add New Row
              </Button>
              {error.status && (
                <FormHelperText sx={{ color: "red" }}>
                  {error.message}
                </FormHelperText>
              )}
            </Stack>
          )}

          {/* If NOT customizing, show manual input table */}
          {!checkedCustomizeFields ? (
            <></>
          ) : (
            // If customizing, show the Excel file upload section instead of manual fields.
            <>
              {!readOnly && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Box sx={{ width: "100%" }}>
                    <Typography sx={{ fontStyle: "italic" }}>
                      Template File:
                    </Typography>
                    <Typography sx={{ fontStyle: "italic" }}>
                      Please download the template file and fill in the details.
                    </Typography>
                  </Box>
                  <Button
                    href="/downloads/Sales Order Numbers and Names Template.xlsx"
                    variant="outlined"
                  >
                    Download Template
                  </Button>
                </Stack>
              )}
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1}
                sx={{ mt: 1 }}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography sx={{ fontStyle: "italic" }}>
                    Attachments:
                  </Typography>
                  <Typography sx={{ fontStyle: "italic" }}>
                    Please attach the Excel sheet of items with Numbers and
                    Names.
                  </Typography>
                </Box>
                {uploadFile ? (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                      padding: "0 10px",
                      borderRadius: 3,
                      backgroundColor: "rgba(239, 239, 239, 0.65)",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography>{truncate(uploadFile)}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Button sx={{ minWidth: 0 }} onClick={removeImage}>
                        x
                      </Button>
                    </Box>
                  </Stack>
                ) : (
                  <Box
                    sx={{
                      borderRadius: "8px",
                      backgroundColor: "rgba(239, 239, 239, 0.65)",
                      border: "1px dashed #9E9E9E",
                      height: "59px",
                      width: "100%",
                      padding: "0 10px",
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ mt: 1 }}
                    >
                      <Box>
                        <Typography>File Name</Typography>
                      </Box>
                      <Box>
                        <label htmlFor="excel-upload">
                          <Button
                            variant="outlined"
                            style={{ width: "60px", pointerEvents: "none" }}
                          >
                            Upload
                          </Button>
                        </label>
                        <input
                          type="file"
                          id="excel-upload"
                          name="excel-upload"
                          accept=".xls,.xlsx"
                          onChange={handleUploadFile}
                          style={{
                            position: "absolute",
                            pointerEvents: "none",
                            visibility: "hidden",
                          }}
                        />
                      </Box>
                    </Stack>
                  </Box>
                )}
              </Stack>
              {uploadFile && (
                <>
                  <iframe
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                      uploadFile
                    )}`}
                    title="Excel Preview"
                    width="100%"
                    height="100%"
                    style={{ border: "none" }}
                  />
                  <Button onClick={downloadUsingAnchorElement}>
                    Download Sizes and Names Excel File
                  </Button>
                </>
              )}
            </>
          )}
          <AlertToast
            toastMessage={toastMessage}
            toastState={toastState}
            handleClose={handleToastClose}
          />
        </>
      )}
    </>
  );
};

SalesOrderItems.defaultProps = {
  sizes: [],
  item_details: [],
};

SalesOrderItems.propTypes = {
  sizes: PropTypes.array,
  item_details: PropTypes.array,
};

export default SalesOrderItems;
